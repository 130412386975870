import React from "react";
import Typography from "@mui/material/Typography";

import cssStyles from "./About.module.scss";

export function getAboutText() {
  return (
    <div className={cssStyles.about}>
      <Typography variant="body1" paragraph>
        India India is a popular Indian restaurant located in the vibrant
        neighborhood of Whitton, in the borough of Richmond upon Thames, London.
      </Typography>

      <Typography variant="body1" paragraph>
        The restaurant's menu offers an extensive range of authentic Indian
        cuisine that caters to all tastes, including vegetarian and
        non-vegetarian options. The dishes are made using the freshest
        ingredients and spices, creating a perfect balance of flavors and aromas
        that tantalize the taste buds.
      </Typography>

      <Typography variant="body1" paragraph>
        India India's attentive and friendly staff are always on hand to provide
        exceptional service and to ensure that customers have an enjoyable
        dining experience. The restaurant offers a delivery and takeaway service
        for customers who prefer to enjoy their meal in the comfort of their own
        home.
      </Typography>
    </div>
  );
}

export function getAdditionalAboutText() {
  return null;
}

export function getAccolades() {
  return [];
}

export function getGalleryMenuImages() {
  return [];
}

export function getGalleryRestaurantImages() {
  return [];
}
